import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import HeadHelmet from "../../components/HeadHelmet/HeadHelmet";
import PageWrapper from "../../components/PageWrapper";

import imgNewsUrl1 from "../../assets/image/snorefreeswiss/bite-cleaned.jpg";
import imgNewsDirtyUrl from "../../assets/image/snorefreeswiss/bite-dirty.jpg";

const renderTextWithFormatting = (text) => {
  // Dividi il testo in linee
  const lines = text.split('\\n');
  const finalOutput = [];

  let currentList = [];

  lines.forEach((line, index) => {
    if (line.trim().startsWith('-')) {
      // Rimuovi '-' e formatta il resto della linea
      let content = line.trim().substring(1).trim();
      let parts = content.split('**');

      // Aggiungi alla lista di elementi <li>
      currentList.push(
        <li key={index}>
          {parts.map((part, partIndex) => (
            partIndex % 2 !== 0 ? <strong key={partIndex}>{part}</strong> : part
          ))}
        </li>
      );
    } else {
      // Se abbiamo una lista aperta e incontriamo una linea non-lista
      if (currentList.length > 0) {
        // Termina la lista corrente e la aggiunge all'output
        finalOutput.push(<ul key={`ul-${index}`}>{currentList}</ul>);
        currentList = []; // Reset della lista
      }

      // Aggiungi la linea corrente come <p>
      let parts = line.split('**');
      finalOutput.push(
        <p className="gr-text-9 text-color-opacity" key={index}>
          {parts.map((part, partIndex) => (
            partIndex % 2 !== 0 ? <strong key={partIndex}>{part}</strong> : part
          ))}
        </p>
      );
    }
  });

  // Al termine dell'iterazione, se c'è una lista non ancora aggiunta
  if (currentList.length > 0) {
    finalOutput.push(<ul key="last-ul">{currentList}</ul>);
  }

  return finalOutput;
};


const News = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "null", // cta, account, null
        }}
      >
        <HeadHelmet
          title='news1_title'
          description='news1_description'
          image={imgNewsUrl1}
        />

        <div className="inner-banner pt-20 pb-8">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h1 className="title gr-text-2 mb-9 heading-color">
                    {t('news1_title')}
                  </h1>
                </div>
              </Col>
              <Col xs="11">
                <div className="pt-lg-9 pb-8">
                  <img src={imgNewsUrl1} alt={t('news1_title') } className="w-100" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <p className="gr-text-9 text-color-opacity">
                      {renderTextWithFormatting(t('news1_b1'))} </p>
                      
                    <div className="pt-lg-9 pb-8 p-10 pr-30">
                      <img src={imgNewsDirtyUrl} alt={t('news_img_caption')} className="w-100" />
                      <p className="gr-text-11" >{t('news_img_caption')} </p>
                    </div>
                    <p className="gr-text-9 text-color-opacity">
                      {renderTextWithFormatting(t('news1_b2'))} </p>
                  </div>
                </div>
                {/* <div className="pt-10">
                  <Link
                    to="/anti-snore-bite-explained/"
                    className="btn-link with-icon text-blue gr-hover-y px-lg-8 px-xl-3"
                  >
                      {t('snore_bite_title')}&nbsp;<i className="fas fa-arrow-right"></i>
                  </Link>
                </div> */}
                <div className="pt-10 pb-20">
                  <Link
                    to="/contact"
                    className="btn btn-blue text-white gr-hover-y px-lg-9"
                  >
                    {t('news1_cta')}&nbsp;&nbsp;<i className="fas fa-arrow-right"></i>
                  </Link>
                </div>

              </Col>

            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default News;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
